<template>
  <div :id="id" :style="`height:${height};`"></div>
</template>

<script>
  import * as echarts from 'echarts'

  export default {
    name: 'Echart05',
    props: ['id', 'height', 'datas', 'color'],
    data() {
      return {

      }
    },
    watch: {
      datas: {
        deep: true,
        handler: function(newV, oldV) {
          if(!newV) return
          this.draw()
        }
      }
    },
    mounted() {
      if(Object.keys(this.datas).length !== 0) {
        this.draw()
      }
    },
    methods: {
      draw() {
        if (document.getElementById(this.id) == null) {
          return
        }
        echarts.dispose(document.getElementById(this.id))
        const myChart = echarts.init(document.getElementById(this.id))
        let option = {
          color: ['rgba(255, 255, 255, .1)', this.color],
          grid: {
            containLabel: true
          },
          calculable: true,
          series: [
            {
              type: 'pie',
              radius: ['74%', '80%'],
              avoidLabelOverlap: false,
              label: {
              	show: false,
                position: 'center',
              },
              labelLine: {
                show: false
              },
              data: [{
              	value: this.datas.budget - this.datas.cumulative,
              	name: '预算',
              	label: {
              		fontSize: 18,
              		color: '#fff',
              		show: true,
              		padding: [8,0,0,0],
              		formatter: [
                    '{project|'+ this.datas.project +'}',
                    '{bl|'+ this.datas.earliestExpiration +'}',
              			'{ys|预算: ' + this.datas.budget + '}',
              			'{sj|实际: ' + this.datas.cumulative + '}',
              			'{tq|同期: ' + this.datas.yearFrom + '}',
              		].join('\n'),
              		rich: {
              			project: {
              				color: 'rgba(255, 255, 255, .8)',
              				fontSize: 14,
              				fontWeight: 'bold',
              				lineHeight: 20
              			},
              			bl: {
              				color: this.color,
                      fontSize: 18,
              				fontWeight: 'bold',
              				lineHeight: 28
              			},
              			ys: {
              				color: 'rgba(255, 255, 255, .6)',
                      fontSize: 12,
                      lineHeight: 18
              			},
              			sj: {
              				color: 'rgba(255, 255, 255, .6)',
                      fontSize: 12,
                      lineHeight: 18
              			},
              			tq: {
              				color: 'rgba(255, 255, 255, .6)',
                      fontSize: 12,
                      lineHeight: 18
              			}
              		},
              	},
              
              }, {
              	value: this.datas.cumulative,
              	name: '累计实际',
              	label: {
              		show:false
              	}
              }]
            }
          ]
        }
        myChart.setOption(option, true)
      }
    }
  }
</script>

<style>
</style>
