<template>
  <div :id="id" :style="`height:${height};`"></div>
</template>

<script>
  import * as echarts from 'echarts'

  export default {
    name: 'Echart02',
    props: ['id', 'height', 'datas'],
    data() {
      return {

      }
    },
    mounted() {
      this.draw()
    },
    methods: {
      draw() {
        if (document.getElementById(this.id) == null) {
          return
        }
        echarts.dispose(document.getElementById(this.id))
        const myChart = echarts.init(document.getElementById(this.id))
        let option = {
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'shadow'
            }
          },
          legend: {
            itemWidth: 16,
            itemHeight : 8,
            data:['合同金额', '收入金额', '毛利金额'],
            textStyle: {
              color: 'rgba(255, 255, 255, .8)'
            },
            padding: [24, 0, 0, 0]
          },
          dataset: {
            dimensions: ['type', 'contractBicycle', 'contractBudgets', 'incomeBicycle','incomeBudgets', 'grossTotle','grossRates'],
            source: this.datas,
          },
          grid: {
            left: '8%',
            right: '8%',
            bottom: '3%',
            top: 50,
            containLabel: true
          },
          xAxis: {
            type: 'category',
            axisLabel: {
            	color: '#2dfcff',
            	fontSize: 12,
              interval: 0, 
              rotate: 30 
            }
          },
          yAxis: {
            type: 'value',
            name: 'w',
            nameTextStyle: {
              fontSize: 10
            },
            splitLine: {
              lineStyle: {
                color:'rgba(255, 255, 255, .1)',
                type: 'dashed'
              }
            },
            axisLabel: {
            	color: 'rgba(255, 255, 255, .6)',
            	fontSize: 10
            },
          },
          series: [{
              name: '合同金额',
              type: 'bar',
              barWidth: 8,
              itemStyle: {
                color: new echarts.graphic.LinearGradient(1, 0, 0, 0, [
                  { offset: 0, color: '#06ff98' },
                  { offset: 1, color: '#2dfcff' }
                ])
              },
              label:{
                show: true,
                position: 'top',
                color: 'rgba(255, 255, 255, .8)',
                fontSize: 10,
                offset: [14, 6],
                rotate: 90 
              }
            },
            {
              name: '全年预算',
              type: 'bar',
              itemStyle: {
                color: 'rgba(255, 255, 255, .0)',
              }
            },
            {
              name: '收入金额',
              type: 'bar',
              barWidth: 8,
              itemStyle: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  { offset: 0, color: '#ffda2d' },
                  { offset: 1, color: '#ff6f06' }
                ])
              },
              label:{
                show: true,
                position: 'top',
                color: 'rgba(255, 255, 255, .8)',
                fontSize: 10,
                offset: [14, 6],
                rotate: 90 
              }
            },
            {
              name: '全年预算',
              type: 'bar',
              itemStyle: {
                color: 'rgba(255, 255, 255, .0)',
              }
            },
            {
              name: '毛利金额',
              type: 'bar',
              barWidth: 8,
              itemStyle: {
                color: new echarts.graphic.LinearGradient(1, 0, 0, 0, [
                  { offset: 0, color: '#ff9dc4' },
                  { offset: 1, color: '#be2dfa' }
                ])
              },
              label:{
                show: true,
                position: 'top',
                color: 'rgba(255, 255, 255, .8)',
                fontSize: 10,
                offset: [14, 6],
                rotate: 90 
              }
            },
            {
              name: '全年预算',
              type: 'bar',
              itemStyle: {
                color: 'rgba(255, 255, 255, .0)',
              }
            }
          ]
        }
        myChart.setOption(option, true)
      }
    }
  }
</script>

<style>
</style>
