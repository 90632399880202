<template>
  <div class="cont-box" v-loading="loading" element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.6)">
    <img class="title-header" src="../../assets/bg-title-bg.png" />
    <div class="icon-list">
      <p v-for="item,index in titles" :key="index" :class="index===curIndex?'icon icon-cur':'icon'" @click="clickTitle(index)">{{item}}</p>
    </div>
    <div v-if="curIndex === 0">
      <div class="list">
        <div class="title-cont title-cont01">
          <img class="title-img" src="../../assets/b-title-des.png" />
          <p class="title">总体经营指标</p>
          <img class="title-img resever" src="../../assets/b-title-des.png" />
          <p class="time" v-if="data03[0].date">( 截止{{data03[0].date.substring(0, 4)+'.'+data03[0].date.substring(5, 7)+' )'}}</p>
        </div>
        <div class="echart-box">
          <Echart05 id="bg03" :datas="data03[0]" color="#ff6f06" height="180px"/>
          <Echart05 id="bg04" :datas="data03[1]" color="#06ff98" height="180px"/>
          <Echart05 id="bg05" :datas="data03[2]" color="#ffda2d" height="180px"/>
          <Echart05 id="bg06" :datas="data03[3]" color="#ff9dc4" height="180px"/>
          <Echart05 id="bg07" :datas="data03[4]" color="#be2dfa" height="180px"/>
          <Echart05 id="bg08" :datas="data03[5]" color="#2dfcff" height="180px"/>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="list">
        <div class="title-cont">
          <img class="title-img" src="../../assets/b-title-des.png" />
          <p class="title">分地区销售分析</p>
          <img class="title-img resever" src="../../assets/b-title-des.png" />
        </div>
        <Echart03 id="bg01" :datas="data01"/>
      </div>
      <div class="list">
        <div class="title-cont">
          <img class="title-img" src="../../assets/b-title-des.png" />
          <p class="title">分产品销售分析</p>
          <img class="title-img resever" src="../../assets/b-title-des.png" />
        </div>
        <Echart04 id="bg02" :datas="data02" height="400px"/>
      </div>
    </div>
  </div>
</template>

<script>
  import Echart03 from '../../components/Echart03.vue'
  import Echart04 from '../../components/Echart04.vue'
  import Echart05 from '../../components/Echart05.vue'
  export default {
    name: 'BG',
    components: {
      Echart03,
      Echart04,
      Echart05
    },
    data() {
      return {
        loading: false,
        titles: ['经营数据分析', '销售数据分析'],
        curIndex: 0,
        data01: [],
        data02: [],
        data03: [{}, {}, {}, {}, {}, {}]
      }
    },
    mounted() {
      this.$axios.post('/totle-index/findList').then( res => {
        this.data03 = res.data.data
      })
      this.$axios.post('/bu-contract-income-gross/findList').then( res => {
        this.data02 = res.data.data
      })
      this.$axios.post('/area-contract-income-gross/findList').then( res => {
        this.data01 = res.data.data.reverse()
      })
    },
    methods: {
      clickTitle(index) {
        this.curIndex = index
      }
    }
  }
</script>

<style scoped>
  .cont-box {
    background-color: #08103c;
    min-height: 100%;
  }

  .title-header {
    width: 100%;
  }

  .resever {
    transform: scaleX(-1);
  }

  .title-cont {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
  }

  .title-img {
    width: 80px;
  }

  .title {
    color: #2dfcff;
    font-size: 16px;
    font-weight: bold;
    padding: 0 10px;
    white-space: nowrap;
  }

  .list {
    padding-bottom: 40px;
  }
  
  .icon-list {
    padding: 0 0 10px;
    display: flex;
    justify-content: center;
    margin: -10px 0 40px;
  }
  .icon {
    width: 40%;
    background: rgba(0, 0, 0, .2);
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid rgba(45, 252, 255, .2);
    font-size: 16px;
    font-weight: bold;
    padding: 10px 0;
    color: rgba(255, 255, 255, .6);
  }
  
  .icon-cur{
    color: white;
    background-color: #1fd6d9;
  }
  
  .title-cont01 {
    margin-bottom: 20px
  }
  .echart-box {
    display: flex;
    flex-wrap: wrap;
    padding: 0 10px;
  }
  .echart-box > div {
    width: 50%;
  }
  .time {
    position: absolute;
    top: 16px;
    left: 50%;
    transform: translateX(-50%);
    color: rgba(255, 255, 255, .6);
    font-size: 12px;
    padding: 6px;
  }
</style>
