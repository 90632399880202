<template>
  <div :id="id" sytle="height: 100px"></div>
</template>

<script>
  import * as echarts from 'echarts'

  export default {
    name: 'Echart03',
    props: ['id', 'datas'],
    data() {
      return {
        height: null
      }
    },
    computed: {
      mapData() {
        return this.datas
      }
    },
    mounted() {
      this.height = this.datas.length > 5 ? this.datas.length*60 : 360
      this.draw()
    },
    methods: {
      draw() {
        if (document.getElementById(this.id) == null) {
          return
        }
        echarts.dispose(document.getElementById(this.id))
        const myChart = echarts.init(document.getElementById(this.id))
        myChart.resize({height: this.height})
        // myChart.on('click', params => {
        //   let id = params.name || params.value
        //   this.$router.push({
        //     path: `/reportDetail/${id}`
        //   })
        // })
        myChart.setOption({
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'shadow'
            }
          },
          legend: {
            itemWidth: 16,
            itemHeight : 8,
            data:['合同金额', '收入金额', '毛利金额'],
            textStyle: {
              color: 'rgba(255, 255, 255, .8)'
            },
            padding: [24, 0, 0, 0]
            
          },
          dataset: {
            dimensions: ['type', 'contractBicycle', 'contractBudgets', 'contractCompletionRates', 'incomeBicycle','incomeBudgets', 'incomeCompletionRates', 'grossTotle', 'grossRates', 'grossCompletionRates'],
            source: this.mapData,
          },
          grid: {
            left: '4%',
            right: '10%',
            bottom: 20,
            top: 50,
            containLabel: true
          },
          xAxis: {
            type: 'value',
            name: 'w',
            nameTextStyle: {
              fontSize: 10
            },
            splitLine: {
              lineStyle: {
                color:'rgba(255, 255, 255, .1)',
                type: 'dashed'
              }
            },
            axisLabel: {
            	color: 'rgba(255, 255, 255, .6)',
            	fontSize: 10,
              interval: 0,
              rotate: 30 
            },
          },
          yAxis: {
            type: 'category',
            triggerEvent: true,
            axisLabel: {
            	color: '#2dfcff',
            	fontSize: 12
            }
          },
          series: [{
              name: '合同金额',
              type: 'bar',
              barWidth: 8,
              itemStyle: {
                color: new echarts.graphic.LinearGradient(1, 0, 0, 0, [
                  { offset: 0, color: '#06ff98' },
                  { offset: 1, color: '#2dfcff' }
                ])
              },
              label:{
                show: true,
                position: 'right',
                color: 'rgba(255, 255, 255, .8)',
                fontSize: 10
              }
            },
            {
              name: '全年预算',
              type: 'bar',
              itemStyle: {
                color: 'rgba(255, 255, 255, .0)',
              }
            },
            {
              name: '完成率',
              type: 'bar',
              itemStyle: {
                color: 'rgba(255, 255, 255, .0)',
              }
            },
            {
              name: '收入金额',
              type: 'bar',
              barWidth: 8,
              barGap: '40%',
              itemStyle: {
                color: new echarts.graphic.LinearGradient(1, 0, 0, 0, [
                  { offset: 0, color: '#ffda2d' },
                  { offset: 1, color: '#ff6f06' }
                ])
              },
              label:{
                show: true,
                position: 'right',
                color: 'rgba(255, 255, 255, .8)',
                fontSize: 10
              }
            },
            {
              name: '全年预算',
              type: 'bar',
              itemStyle: {
                color: 'rgba(255, 255, 255, .0)',
              }
            },
            {
              name: '完成率',
              type: 'bar',
              itemStyle: {
                color: 'rgba(255, 255, 255, .0)',
              },
              itemStyle: {
                color: 'rgba(255, 255, 255, .0)',
              }
            },
            {
              name: '毛利金额',
              type: 'bar',
              barWidth: 8,
              barGap: '40%',
              itemStyle: {
                color: new echarts.graphic.LinearGradient(1, 0, 0, 0, [
                  { offset: 0, color: '#ff9dc4' },
                  { offset: 1, color: '#be2dfa' }
                ])
              },
              label:{
                show: true,
                position: 'right',
                color: 'rgba(255, 255, 255, .8)',
                fontSize: 10
              }
            },
            {
              name: '全年预算',
              type: 'bar',
              itemStyle: {
                color: 'rgba(255, 255, 255, .0)',
              }
            },
            {
              name: '完成率',
              type: 'bar',
              itemStyle: {
                color: 'rgba(255, 255, 255, .0)',
              }
            }
          ]
        }, true)
      }
    }
  }
</script>

<style>
</style>
